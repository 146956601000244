









import SocialWall from "@/components/landing/SocialWall.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({ components: { SocialWall } })
export default class SocialView extends Vue {
  get topic() {
    return this.$route.params.topic;
  }
}
